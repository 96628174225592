<script setup lang="ts">
import type { PropType } from 'vue'
import type { Image } from '~/types'
import emblaCarouselVue from 'embla-carousel-vue'

const props = defineProps({
	id: {
		type: [String, Number],
		required: true
	},
	images: {
		type: Array as PropType<Image[]>,
		default: null
	},
	youtubeUrls: {
		type: Array as PropType<string[]>,
		default: null
	},
	alt: {
		type: String,
		default: ''
	},
	groupName: {
		type: String,
		default: 'group'
	},
	width: {
		type: Number,
		default: 300,
	},
	height: {
		type: Number,
		default: 200
	},
	thumbNumber: {
		type: Number,
		default: 3
	},
	thumbNumberMobile: {
		type: Number,
		default: undefined
	},
	scrollEnabled: {
		type: Boolean,
		default: true
	},
	showCounters: {
		type: Boolean,
		default: true
	},
	showArrows: {
		type: Boolean,
		default: false
	},
	showThumbs: {
		type: Boolean,
		default: true
	},
	showDots: {
		type: Boolean,
		default: false
	},
	showThumbsMobile: {
		type: Boolean,
		default: false
	},
	enableFancybox: {
		type: Boolean,
		default: false
	},
	goTitle: {
		type: String,
		default: undefined
	},
	goUrl: {
		type: String,
		default: undefined
	},
	density: {
		type: Number,
		default: 0
	},
	aspectClass: {
		type: String,
		default: 'aspect-[3/2]'
	}
})

const youtubeHelper = useYoutubeHelper()
const embla = useEmbla()

const [emblaMain, emblaApiMain] = emblaCarouselVue({
	active: false,
})

const [emblaThumbs, emblaApiThumbs] = emblaCarouselVue({
	active: false,
	containScroll: 'keepSnaps',
	dragFree: true
})

const emblaDots = ref()

const currentIndex = ref(0)
const lastVisibleIndex = ref(props.thumbNumber - 1)

const onFancyboxChange = (index: number) => {
	emblaApiMain?.value?.scrollTo(index, true)
}

const countItems = ref(props.youtubeUrls?.length + props.images?.length)

const isShowOverlay = (index: number) => {
	if (countItems.value < props.thumbNumber) {
		return false
	}
	const realIndex = props.youtubeUrls?.length + index
	if (props.images?.length - 1 - index < 1) {
		return false
	}
	return (
		realIndex === lastVisibleIndex.value
	)
}

const isCanPrev = computed(() => {
	return currentIndex.value > 0
})

const isCanNext = computed(() => {
	return countItems.value > 1 && currentIndex.value < countItems.value - 1
})

const onPrev = () => {
	emblaApiMain?.value?.scrollPrev()
}

const onNext = () => {
	emblaApiMain?.value?.scrollNext()
}

const onScrollTo = (index: number) => {
	emblaApiMain?.value?.scrollTo(index, true)
}

const onScrollThumbs = () => {
	if (!emblaApiThumbs?.value) {
		return
	}
	const items = emblaApiThumbs.value.slidesInView()
	if (!Array.isArray(items)) {
		return
	}
	
	lastVisibleIndex.value = items.length > props.thumbNumber - 1
		? items[props.thumbNumber - 1]
		: items.at(-1) ?? 0
}

const getPercent = (number: number) => {
	switch (number) {
		case 2:
			return { base: 'flex-[0_0_50%]', md: 'md:flex-[0_0_50%]' }
		case 3:
			return { base: 'flex-[0_0_33.33%]', md: 'md:flex-[0_0_33.33%]' }
		case 4:
			return { base: 'flex-[0_0_25%]', md: 'md:flex-[0_0_25%]' }
		case 5:
			return { base: 'flex-[0_0_20%]', md: 'md:flex-[0_0_20%]' }
		case 6:
			return { base: 'flex-[0_0_16.66%]', md: 'md:flex-[0_0_16.66%]' }
		case 7:
			return { base: 'flex-[0_0_14.28%]', md: 'md:flex-[0_0_14.28%]' }
		case 8:
			return { base: 'flex-[0_0_12.5%]', md: 'md:flex-[0_0_12.5%]' }
		case 9:
			return { base: 'flex-[0_0_11.11%]', md: 'md:flex-[0_0_11.11%]' }
		case 10:
			return { base: 'flex-[0_0_10%]', md: 'md:flex-[0_0_10%]' }
		default:
			return { base: 'flex-[0_0_100%]', md: 'md:flex-[0_0_100%]' }
	}
}

onMounted(() => {
	
	setTimeout(() => {
		if (emblaApiMain?.value) {
			emblaApiMain.value.reInit({
				active: props.scrollEnabled,
			})
			
			emblaApiMain.value.on('select', () => {
				currentIndex.value = emblaApiMain.value?.selectedScrollSnap() ?? 0
			})
		}
		
		if (emblaApiThumbs?.value) {
			emblaApiThumbs.value.reInit({
				active: props.scrollEnabled,
				containScroll: 'keepSnaps',
				dragFree: true
			})
		}
	}, 1000)
	
	if (emblaApiMain?.value && emblaApiThumbs?.value) {
		const removeThumbClickHandlers = embla.addThumbClickHandlers(
			emblaApiMain.value,
			emblaApiThumbs.value,
		)
		
		const removeToggleThumbActive = embla.addToggleThumbActive(
			emblaApiMain.value,
			emblaApiThumbs.value,
			currentIndex,
		)
		
		emblaApiMain.value
			.on('destroy', removeThumbClickHandlers)
			.on('destroy', removeToggleThumbActive)
		
		emblaApiThumbs.value
			.on('scroll', onScrollThumbs)
			.on('destroy', removeThumbClickHandlers)
			.on('destroy', removeToggleThumbActive)
	}
	
	if (props.showDots && emblaApiMain?.value && emblaDots?.value) {
		embla.addDotAndClickHandlers(emblaApiMain.value, emblaDots.value)
	}
})
</script>

<template>
	<div class="relative overflow-hidden m-auto max-w-full md:rounded-md">
		<UiFancybox
			:is-enabled="enableFancybox"
			:on-change="onFancyboxChange"
			:options="{
				Carousel: {
		      transition: 'classic',
				},
				Image: {
          zoom: false,
        },
        Toolbar: {
			    display: {
			      left: [],
			      middle: [],
						right: ['close'],
					},
				},
				Thumbs: {
		      type: 'classic',
				},
				fullscreen: {
					autoStart: true
				}
			}"
		>
			<div
				ref="emblaMain"
				class="overflow-hidden md:rounded-md relative bg-stone-50 group"
				:class="[aspectClass]"
			>
				<div class="flex backface-hidden touch-pan-y touch-pinch-zoom ml-[calc(1rem*-1)]">
					<template v-if="images?.length > 0">
						<div
							v-for="(image, index) in images"
							:key="image.id"
							class="pl-4 flex-[0_0_100%] min-w-0"
						>
							<div
								class="w-full md:rounded-md bg-stone-50 overflow-hidden"
								:class="[aspectClass]"
							>
								<NuxtLink
									v-if="goUrl"
									:to="goUrl"
									:aria-label="goTitle"
								>
									<UiImage
										:url-server="image.url"
										:url-cdn="image.cdn_url"
										:width="width"
										:height="height"
										:density="density"
										:alt="alt"
										class="block w-full h-full"
									/>
								</NuxtLink>
								<a
									v-else
									:data-fancybox="groupName + '_' + id"
									:href="goUrl ?? image.original_url"
									class="block w-full h-full"
									:aria-label="'Photo - ' + (index + 1)"
								>
									<UiImage
										:url-server="image.url"
										:url-cdn="image.cdn_url"
										:width="width"
										:height="height"
										:density="density"
										:alt="alt"
										class="block w-full h-full"
									/>
								</a>
							</div>
						</div>
					</template>
					<template v-if="youtubeUrls?.length > 0">
						<div
							v-for="(url, index) in youtubeUrls"
							:key="index + 100"
							class="pl-4 flex-[0_0_100%] min-w-0"
						>
							<div
								class="relative md:rounded-md bg-stone-50 overflow-hidden"
								:class="[aspectClass]"
							>
								<NuxtLink
									v-if="goUrl"
									:to="goUrl"
									:aria-label="goTitle"
								>
									<UiImage
										v-if="youtubeHelper.getId(url)"
										:density="density"
										:urls="[
											{
												jpg: youtubeHelper.getThumbUrl(url, 'mqdefault')!,
												webp: youtubeHelper.getThumbUrl(url, 'mqdefault', 'webp')!,
											},
											{
												jpg: youtubeHelper.getThumbUrl(url, 'sddefault')!,
												webp: youtubeHelper.getThumbUrl(url, 'sddefault', 'webp')!,
											},
											{
												jpg: youtubeHelper.getThumbUrl(url, 'hqdefault')!,
												webp: youtubeHelper.getThumbUrl(url, 'hqdefault', 'webp')!,
											},
										]"
										:alt="alt"
										class="block w-full h-full"
									/>
									<div class="absolute flex items-center justify-center inset-0 z-50">
										<UIcon
											name="i-app-youtube-red"
											class="w-1/6 h-1/6"
										/>
									</div>
								</NuxtLink>
								<a
									v-else
									:data-fancybox="groupName + '_' + id"
									data-type="html5video"
									:href="url"
									:aria-label="'Photo - ' + (index + 1)"
									class="block w-full h-full"
								>
									<UiImage
										v-if="youtubeHelper.getId(url)"
										:density="density"
										:urls="[
											{
												jpg: youtubeHelper.getThumbUrl(url, 'mqdefault')!,
												webp: youtubeHelper.getThumbUrl(url, 'mqdefault', 'webp')!,
											},
											{
												jpg: youtubeHelper.getThumbUrl(url, 'hqdefault')!,
												webp: youtubeHelper.getThumbUrl(url, 'hqdefault', 'webp')!,
											},
											{
												jpg: youtubeHelper.getThumbUrl(url, 'hqdefault')!,
												webp: youtubeHelper.getThumbUrl(url, 'hqdefault', 'webp')!,
											},
										]"
										:alt="alt"
										class="block w-full h-full"
									/>
									<div class="absolute flex items-center justify-center inset-0 z-50">
										<UIcon
											name="i-app-youtube-red"
											class="w-1/6 h-1/6"
										/>
									</div>
								</a>
							</div>
						</div>
					</template>
				</div>
				<div
					v-if="showDots"
					class="absolute bottom-0 left-0 right-0 flex items-center justify-center"
				>
					<div
						ref="emblaDots"
						class="flex items-center h-6 gap-[2px]"
					/>
				</div>
				<UButton
					v-if="showArrows && countItems > 1"
					variant="link"
					class="absolute opacity-0 group-hover:opacity-100 transition-opacity left-2 top-[50%] -translate-y-1/2 h-[20%] max-h-[60px] disabled:opacity-0 disabled:group-hover:!opacity-50 disabled:cursor-default disabled:pointer-events-auto"
					aria-label="Previous"
					:disabled="!isCanPrev"
					@click="onPrev"
				>
					<svg class="w-full h-full" width="16px" height="35px" viewBox="0 0 16 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
							<g transform="translate(2, 2)" stroke="#FFFFFF" stroke-width="4">
								<polyline id="Path" points="11.83737 8.8817842e-16 0 15.98406 11.83737 30.92916" />
							</g>
						</g>
					</svg>
				</UButton>
				<UButton
					v-if="showArrows && countItems > 1"
					variant="link"
					class="absolute opacity-0 group-hover:opacity-100 transition-opacity right-2 top-[50%] -translate-y-1/2 h-[20%] max-h-[60px] z-50 disabled:group-hover:opacity-50 disabled:cursor-default disabled:pointer-events-auto"
					aria-label="Next"
					:disabled="!isCanNext"
					@click="onNext"
				>
					<svg class="w-full h-full" width="16px" height="35px" viewBox="0 0 16 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
							<g transform="translate(2, 2)" stroke="#FFFFFF" stroke-width="4">
								<polyline id="Path" points="4.4408921e-16 30.92915 11.83737 14.94515 4.4408921e-16 0" />
							</g>
						</g>
					</svg>
					<img v-if="false" src="/images/icons/icon_arrow_next_white.svg" class="w-full h-full" alt="Right" />
				</UButton>
			</div>
		</UiFancyBox>
		<div
			v-if="showThumbs"
			class="embla-thumb overflow-hidden mt-2 px-2 md:px-0"
			:class="[
				showThumbsMobile ? 'flex' : 'hidden md:flex'
			]"
		>
			<div
				v-if="youtubeUrls?.length > 0"
				class="mr-2 cursor-pointer"
				@click="onScrollTo(images?.length)"
			>
				<div class="relative rounded-md bg-stone-50 border overflow-hidden aspect-[3/2] min-h-[68px] w-full">
					<div class="w-full h-full"></div>
					<div class="absolute flex items-center justify-center inset-0 z-50">
						<UIcon
							name="i-app-youtube-red"
							class="w-1/2 h-1/2"
						/>
					</div>
				</div>
			</div>
			<div
				ref="emblaThumbs"
				class="relative overflow-hidden rounded-md w-full"
			>
				<div class="flex ml-[calc(0.5rem*-1)]">
					<template v-if="images?.length > 0">
						<div
							v-for="(image, index) in images"
							:key="image.id"
							class="pl-[0.5rem] min-w-0"
							:class="[
								thumbNumberMobile
									? getPercent(thumbNumberMobile!)['base'] + ' ' + getPercent(thumbNumber)['md']
									: getPercent(thumbNumber)['base'],
							]"
						>
							<div
								class="relative w-full rounded-md bg-stone-50 overflow-hidden cursor-pointer"
								:class="[aspectClass]"
							>
								<UiImage
									:url-server="image.url"
									:url-cdn="image.cdn_url"
									:width="90"
									:height="60"
									:alt="alt"
									class="w-full opacity-90"
								/>
								<div class="overlay bg-black bg-opacity-50 absolute z-10 inset-0 transition-all duration-300"></div>
								<div
									v-if="isShowOverlay(index)"
									class="flex items-center justify-center absolute inset-0 z-20 text-white text-sm font-light"
								>
									+{{ (images.length - 1) - index }}
								</div>
							</div>
						</div>
					</template>
					<template v-if="youtubeUrls?.length > 0">
						<div
							v-for="(url, index) in youtubeUrls"
							:key="index + 100"
							class="pl-[0.5rem] min-w-0 min-h-11"
							:class="[
								{ 'selected': !index },
								thumbNumberMobile
									? getPercent(thumbNumberMobile!)['base'] + ' ' + getPercent(thumbNumber)['md']
									: getPercent(thumbNumber)['base'],
							]"
						>
							<div
								class="relative w-full h-full rounded-md bg-stone-50 overflow-hidden cursor-pointer"
								:class="[aspectClass]"
							>
								<UiImage
									v-if="youtubeHelper.getId(url)"
									:urls="[
										{
											jpg: youtubeHelper.getThumbUrl(url, 'default')!,
											webp: youtubeHelper.getThumbUrl(url, 'default', 'webp')!,
										},
										{
											jpg: youtubeHelper.getThumbUrl(url, 'mqdefault')!,
											webp: youtubeHelper.getThumbUrl(url, 'mqdefault', 'webp')!,
										},
										{
											jpg: youtubeHelper.getThumbUrl(url, 'sddefault')!,
											webp: youtubeHelper.getThumbUrl(url, 'sddefault', 'webp')!,
										}
									]"
									:alt="alt"
									class="w-full opacity-90"
								/>
								<div class="bg-stone-100"></div>
								<div class="overlay bg-black bg-opacity-50 absolute z-10 inset-0 transition-all duration-300"></div>
								<div class="absolute flex items-center justify-center inset-0 z-50">
									<UIcon
										name="i-app-youtube-red"
										class="w-1/6 h-1/6"
									/>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div
			v-if="showCounters"
			class="absolute top-2 right-2 flex gap-1 pointer-events-none"
		>
			<UBadge
				v-if="youtubeUrls?.length"
				color="white"
				:ui="{
					base: 'flex items-center gap-1',
					rounded: 'rounded-full',
					color: {
						white: {
							solid: 'ring-0 bg-opacity-80'
						}
					}
				}"
			>
				<UIcon
					name="i-app-camera"
					class="w-3"
				/>
			</UBadge>
			<UBadge
				v-if="images?.length"
				color="white"
				:ui="{
					base: 'flex items-center gap-1',
					rounded: 'rounded-full',
					color: {
						white: {
							solid: 'ring-0 bg-opacity-80'
						}
					}
				}"
			>
				<UIcon
					name="i-app-photo"
					class="w-3"
				/>
				<span class="text-xs font-normal">
					{{ images?.length }}
				</span>
			</UBadge>
		</div>
	</div>
</template>