import { createSharedComposable } from '@vueuse/core'

const _useYoutubeHelper = () => {
	const getId = (url: string): string | null => {
		const youtubeIdRegex =
			/^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*(?:\/|v\/|u\/|embed\/|shorts\/|watch\?v=)(?<id>[\w\-]{11})(?=\?|&|$)/

		const match = url.match(youtubeIdRegex)

		if (match && match[0].includes('/user/')) {
			return null
		}

		return match?.groups?.id || null;
	}

	const getThumbUrl = (url: string, name: string, ext: string = 'jpg'): string | null => {
		const id = getId(url)

		const format = ext === 'webp' ? 'vi_webp' : 'vi'

		if (id) {
			return 'https://i3.ytimg.com/' + format + '/' + id + '/' + name + '.' + ext
		}
		return null
	}

	return {
		getId,
		getThumbUrl,
	}
}

export const useYoutubeHelper = createSharedComposable(_useYoutubeHelper)