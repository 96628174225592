import type { EmblaCarouselType } from 'embla-carousel'
import type { Ref } from 'vue'

export const useEmbla = () => {
	const addThumbClickHandlers = (
		emblaApiMain: EmblaCarouselType,
		emblaApiThumb: EmblaCarouselType,
	): (() => void) => {
		const slidesThumbs = emblaApiThumb.slideNodes()

		const scrollToIndex = slidesThumbs.map(
			(_, index) => (): void => emblaApiMain.scrollTo(index)
		)

		slidesThumbs.forEach((slideNode, index) => {
			slideNode.addEventListener('click', scrollToIndex[index], false)
		})

		return (): void => {
			slidesThumbs.forEach((slideNode, index) => {
				slideNode.removeEventListener('click', scrollToIndex[index], false)
			})
		}
	}

	const addToggleThumbActive = (
		emblaApiMain: EmblaCarouselType,
		emblaApiThumb: EmblaCarouselType,
		currentIndex: Ref<number>,
	): (() => void) => {
		const slidesThumbs = emblaApiThumb.slideNodes()

		const toggleThumbState = (): void => {
			emblaApiThumb.scrollTo(emblaApiMain.selectedScrollSnap())
			const previous = emblaApiMain.previousScrollSnap()
			const selected = emblaApiMain.selectedScrollSnap()

			currentIndex.value = selected

			slidesThumbs[previous]?.classList?.remove('selected')
			slidesThumbs[selected]?.classList?.add('selected')
		}

		emblaApiMain.on('select', toggleThumbState)
		emblaApiThumb.on('init', toggleThumbState)

		return (): void => {
			const selected = emblaApiMain.selectedScrollSnap()
			currentIndex.value = selected
			slidesThumbs[selected]?.classList?.remove('selected')
		}
	}

	const addDotAndClickHandlers = (
		emblaApi: EmblaCarouselType,
		dotsNode: HTMLElement
	): (() => void) => {
		let dotNodes: HTMLElement[] = []

		const addDotWithClickHandlers = (): void => {
			dotsNode.innerHTML = emblaApi
				.scrollSnapList()
				.map(() => '<button class="embla__dot" type="button"></button>')
				.join('')

			const scrollTo = (index: number): void => {
				emblaApi.scrollTo(index)
			}

			dotNodes = Array.from(dotsNode.querySelectorAll('.embla__dot'))
			dotNodes.forEach((dotNode, index) => {
				dotNode.addEventListener('click', () => scrollTo(index), false)
			})
		}

		const toggleDotActive = (): void => {
			const previous = emblaApi.previousScrollSnap()
			const selected = emblaApi.selectedScrollSnap()
			dotNodes[previous].classList.remove('embla__dot--selected')
			dotNodes[selected].classList.add('embla__dot--selected')
		}

		emblaApi
			.on('init', addDotWithClickHandlers)
			.on('reInit', addDotWithClickHandlers)
			.on('init', toggleDotActive)
			.on('reInit', toggleDotActive)
			.on('select', toggleDotActive)

		return (): void => {
			dotsNode.innerHTML = ''
		}
	}

	return {
		addThumbClickHandlers,
		addToggleThumbActive,
		addDotAndClickHandlers
	}
}